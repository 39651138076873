import React, { useState, useEffect, useContext } from "react"

import Layout from "../components/layout"
import NewButton from "../components/NewButton"
import OrderFlow from "../components/OrderFlow"
import CartContext from "../context/cartContext"
import SEO from "../components/seo"

const HubbleContacts = () => {
  const { cartItems, createOrder, clearOrder } = useContext(CartContext)
  const [show, setShow] = useState(false)

  useEffect(() => {
    const newItem = {
      id: 1,
      image: "Cart/HubbleContacts.png",
      title: "Hubble Daily Contacts",
      period: "Every 4 weeks",
      price: 1,
      type: "contacts",
    }

    const newOrder = {
      prescription: {
        rightEye: {
          baseCurve: "8.5",
          diameter: "14.2",
          power: "",
        },
        leftEye: {
          baseCurve: "8.5",
          diameter: "14.2",
          power: "",
        },
      },
      isOneEye: "",
      email: "",
      state: "",
      doctor: "",
    }

    createOrder(newItem, newOrder)

    return () => clearOrder()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems])

  return (
    <Layout>
      <SEO title={`Hubble Contacts | Frames by Hubble`} />
      <NewButton label="Buy Now" onClick={() => setShow(true)} />
      <OrderFlow show={show} setShow={setShow} type="contacts" brand='classic'/>
    </Layout>
  )
}

export default HubbleContacts
